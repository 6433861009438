.App {
  display: flex;
  flex-flow: column;
  width: 100vw;
  min-height: 100vh;
  background-color: #2a2a2b;
}

.NavBar{
  height: 7vh;
  width: 85vw;
}

.banner-container{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3vh;
}

.hero-block{
  gap: 5vw;
  align-self: left;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  align-content: left;
  justify-items: left;
  margin-top: 7vh;
  margin-left: 3vw;
  order: 2;
  width: 97vw;
  height: 80vh;
}

/* The hero image */
.hero-image {
  display: flex;
  order: 1;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: url("/src/images/michael-business.jpg");
  /* Set a specific height */
  width: 30vw;
  height: 80vh;
  filter: grayscale(100%);
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.blurb-pic{
  display: flex;
  order: 2;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("images/landscape.jpg");
  filter: grayscale(100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 60vw;
  height: 80vh;
  z-index: 1;
}

.hero-blurb{
  display: flex;
  order: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 60vw;
  height: 80vh;
  margin-left: 5vw;
  z-index: 10;
  /* font styling */
  text-align: center;
  font-weight: bold;
  font-size: 45px;
  color: #FFFF;
}

.hero-title{
  order: 3;
  flex-grow: 2;
  margin-left: 11vw;
  font-size: 45px;
  color: #FFFF;
}

.section-title{
  padding-top: 5vh;
  order: 1;
  margin-left: 11vw;
  font-size: 35px;
  color: #FFFF;
}

.project-list{
  margin-left: 11vw;
  justify-content: center;
  flex-direction: column;
  order: 2;
  width: 80vw;
  padding-top: 3vh;
}

.project-item{
  display: flex;
  margin-bottom: 15px;
}

.about-block{
  margin-left: 11vw;
  flex-direction: row;
  order: 2;
  width: 80vw;
  padding-top: 3vh; 
}

.icon-list{
  display: flex;
  gap: 10px;
  padding-top: 3vh;
  align-self: center;
  flex-direction: row;
}

.about-image{
  display: flex;
  order: 2;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("images/michael-hiking.jpg");
  filter: grayscale(100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30vw;
  height: 80vh;
  z-index: 1;
  
}

.about-blurb{
  display: flex;
  order: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 60vw;
  height: 80vh;
  z-index: 10;
  /* font styling */
  font-size: 30px;
  color: #FFFF;
}
  

